.info-box {
  color: white;
  border-radius: 5px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
  transition: all 0.2s;
  min-width: 10.5em;
}
.info-box:hover {
  transform: translateY(-0.3rem);
  box-shadow: 0 0.3rem 1rem rgba(0, 0, 0, 0.2);
}

.info-box:active {
  box-shadow: 0 0.2rem 0.5rem rgba(0, 0, 0, 0.2);
  transform: translateY(0px);
}

.info-box-icon-full {
  border-top-left-radius: 2px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 2px;
  display: block;
  float: left;
  width: 100%;
  text-align: center;
  font-size: 45px;
  line-height: 90px;
}

.info-box-number {
  width: 100%;
  display: inline-block;
  font-weight: 500;
}

.email-list {
  list-style-type: none;
}

.text-link {
  color: blue;
  outline: none;
  border: none;
  width: 100%;
}
