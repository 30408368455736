.login-img {
  margin-top: 5em;
}

.login-text {
  font-weight: 700;
  font-size: 30px;
  color: #2b2d6b;
}

.email-confirmation-text {
  font-weight: 400;
  font-size: 12px;
  color: #535665;
}
.carousel-control-prev,
.carousel-control-next {
  width: 200px;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  height: 50px;
  width: 30px;
  outline: black;
  background-size: 100%, 100%;
  background-image: none;
}

.carousel-control-next-icon:after {
  content: '>';
  font-size: 30px;
  color: black;
}

.carousel-control-prev-icon:after {
  content: '<';
  font-size: 30px;
  color: black;
}
