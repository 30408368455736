@keyframes animacao_forward_0 {
  from {
    transform: translate(280px);
  }
  to {
    transform: translate(0);
  }
}

@keyframes animacao_forward_1 {
  from {
    transform: translate(280px);
  }
  to {
    transform: translate(0);
  }
}

@keyframes animacao_forward_2 {
  from {
    transform: translate(280px);
  }
  to {
    transform: translate(0);
  }
}
@keyframes animacao_forward_3 {
  from {
    transform: translate(280px);
  }
  to {
    transform: translate(0);
  }
}
@keyframes animacao_forward_4 {
  from {
    transform: translate(280px);
  }
  to {
    transform: translate(0);
  }
}
@keyframes animacao_forward_5 {
  from {
    transform: translate(280px);
  }
  to {
    transform: translate(0);
  }
}
@keyframes animacao_forward_6 {
  from {
    transform: translate(280px);
  }
  to {
    transform: translate(0);
  }
}
@keyframes animacao_forward_7 {
  from {
    transform: translate(280px);
  }
  to {
    transform: translate(0);
  }
}
@keyframes animacao_forward_8 {
  from {
    transform: translate(280px);
  }
  to {
    transform: translate(0);
  }
}
@keyframes animacao_forward_9 {
  from {
    transform: translate(280px);
  }
  to {
    transform: translate(0);
  }
}
@keyframes animacao_forward_10 {
  from {
    transform: translate(280px);
  }
  to {
    transform: translate(0);
  }
}

.box_forward_0 {
  animation: animacao_forward_0 0.8s;
}
.box_forward_1 {
  animation: animacao_forward_1 0.8s;
}
.box_forward_2 {
  animation: animacao_forward_2 0.8s;
}
.box_forward_3 {
  animation: animacao_forward_3 0.8s;
}
.box_forward_5 {
  animation: animacao_forward_5 0.8s;
}
.box_forward_6 {
  animation: animacao_forward_6 0.8s;
}
.box_forward_7 {
  animation: animacao_forward_7 0.8s;
}
.box_forward_8 {
  animation: enimacao_forward_8 0.8s;
}
.box_forward_9 {
  animation: animacao_forward_9 0.8s;
}
.box_forward_10 {
  animation: animacao_forward_4 0.8s;
}
.box_forward_4 {
  animation: animacao_forward_4 0.8s;
}

@keyframes animacao_backward_0 {
  from {
    transform: translate(-280px);
  }
  to {
    transform: translate(0);
  }
}

@keyframes animacao_backward_1 {
  from {
    transform: translate(-280px);
  }
  to {
    transform: translate(0);
  }
}

@keyframes animacao_backward_2 {
  from {
    transform: translate(-280px);
  }
  to {
    transform: translate(0);
  }
}

@keyframes animacao_backward_3 {
  from {
    transform: translate(-280px);
  }
  to {
    transform: translate(0);
  }
}

@keyframes animacao_backward_4 {
  from {
    transform: translate(-280px);
  }
  to {
    transform: translate(0);
  }
}

@keyframes animacao_backward_5 {
  from {
    transform: translate(-280px);
  }
  to {
    transform: translate(0);
  }
}

@keyframes animacao_backward_6 {
  from {
    transform: translate(-280px);
  }
  to {
    transform: translate(0);
  }
}

@keyframes animacao_backward_7 {
  from {
    transform: translate(-280px);
  }
  to {
    transform: translate(0);
  }
}

@keyframes animacao_backward_8 {
  from {
    transform: translate(-280px);
  }
  to {
    transform: translate(0);
  }
}

@keyframes animacao_backward_9 {
  from {
    transform: translate(-280px);
  }
  to {
    transform: translate(0);
  }
}

@keyframes animacao_backward_10 {
  from {
    transform: translate(-280px);
  }
  to {
    transform: translate(0);
  }
}

.box_backward_0 {
  animation: animacao_backward_0 0.8s;
}
.box_backward_1 {
  animation: animacao_backward_1 0.8s;
}

.box_backward_2 {
  animation: animacao_backward_2 0.8s;
}

.box_backward_3 {
  animation: animacao_backward_3 0.8s;
}

.box_backward_4 {
  animation: aimacao_backward_4 0.8s;
}

.box_backward_5 {
  animation: animacao_backward_5 0.8s;
}

.box_backward_6 {
  animation: animacao_backward_6 0.8s;
}

.box_backward_7 {
  animation: animacao_backward_7 0.8s;
}

.box_backward_8 {
  animation: animacao_backward_8 0.8s;
}

.box_backward_9 {
  animation: animacao_backward_9 0.8s;
}

.box_backward_10 {
  animation: animacao_backward_10 0.8s;
}
