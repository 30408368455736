.fc-button-primary {
  background-color: #999999 !important;
  border-color: #999999 !important;
}

.fc-button-active {
  background-color: #cccccc !important;
  border-color: #cccccc !important;
}
.card {
  width: 100%;
  height: 100%;
}
