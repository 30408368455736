$green-primary-otimigas: #ff007b;
$green-secondary-otimigas: #99004a;
$blue-primary-otimigas: #712eff;
$blue-secondary-otimigas: #5e28d4;


html {
  -webkit-text-size-adjust: none;
}
.content {
  min-height: 250px;
  padding: 15px;
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
}

body {
  height: 100vh;
  background-color: #ffffff;
  position: relative;
}

#root {
  background-color: #ffffff;
}

button {
  border: 0;
  padding: 0;
  background: inherit;
}

.font-dosis {
  font: 16px OpenSans, Arial, Helvetica, sans-serif;
}

.MuiDialogContent-root:first-child {
  padding: 0px !important;
}

.MuiDialogContent-root {
  padding: 0px !important;
}

.title {
  font-weight: 400;
  font-size: 48px;
  line-height: 58px;
  letter-spacing: 0.25px;
  color: #282828;
  margin: 0;
}

.body {
  font-weight: 400;
  font-size: 18px;
  line-height: 42px;
  letter-spacing: 0.25px;
  color: #282828;
  margin: 0;
}

.body-title {
  font-weight: 700;
  font-size: 32px;
  line-height: 38px;
  letter-spacing: 0.25px;
  color: #282828;
  margin: 0;
}

.h2 {
  font-weight: 400;
  font-size: 32px;
  line-height: 38px;
  letter-spacing: 0.25px;
  color: #282828;
  margin: 0;
}

.h2-bold {
  font-weight: 700;
  font-size: 32px;
  line-height: 38px;
  letter-spacing: 0.25px;
  color: #282828;
  margin: 0;
}

.h3 {
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 0.25px;
  color: #282828;
  margin: 0;
}

.h3-bold {
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 0.25px;
  color: #282828;
  margin: 0;
}

.h4 {
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.25px;
  color: #282828;
  margin: 0;
}

.h4-bold {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.25px;
  color: #282828;
  margin: 0;
}

.h5 {
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.25px;
  color: #282828;
  margin: 0;
}

.h5-bold {
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.25px;
  color: #282828;
  margin: 0;
}

.h6 {
  font-weight: 400;
  font-size: 16px;
  line-height: 160%;
  letter-spacing: 0.25px;
  color: #282828;
  margin: 0;
}

.h6-bold {
  font-weight: 700;
  font-size: 16px;
  line-height: 160%;
  letter-spacing: 0.25px;
  color: #282828;
  margin: 0;
}

.h7 {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.25px;
  color: #282828;
  margin: 0;
}
