.station-font-1 {
  font-size: 1rem;
}

.station-font-regular {
  font-weight: 400;
}

.station-font-medium {
  font-weight: 500;
}

.station-font-bold {
  font-weight: 700;
}

.station-font-black {
  font-weight: 900;
}

.station-font-semibold {
  font-weight: 600;
}

.station-font-lh-2 {
  line-height: 2rem;
}

.activity-description {
  font-size: 1.15rem;
}

.precise-activity-description > div > p > span {
  font-size: 1.15rem;
}

html {
  font-size: 16px !important;
}

.station-container a:hover {
  text-decoration: none !important;
  color: lightgray !important;
}

.container-fluid {
  padding: 0 !important;
  overflow: hidden;
}

a {
  font-size: 1rem;
}

h1 {
  font-size: 3.5rem;
}

h4 {
  font-size: 1.5rem;
}

h6 {
  font-size: 1rem;
}

button {
  cursor: pointer;
  border-radius: 4px !important;
}

span {
  font-size: 1rem;
}

/* .container-fluid {
  background-color: #fff;
} */

.h1-website {
  font-size: 0.625rem;
  font-weight: 700;
  text-transform: uppercase;
}

.h2-website-mobile {
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 2.25rem;
}

.h2-website {
  font-size: 2.5rem;
  font-weight: 700;
  line-height: 3rem;
}

.col-12 {
  max-width: 1280px;
}

.chevronSlider {
  border-radius: 10px !important;
}

p {
  overflow-wrap: break-word;
}
