.privacy-policy-text a {
  display: block;
  text-align: left;
  margin-bottom: 4px;
}

.brb-fla-policy b {
  font-size: large;
  line-height: 4rem;
}

.brb-fla-policy p {
  /* font-size: large; */
  line-height: 2rem;
}
