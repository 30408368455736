.use-policy-text h2,
.use-policy-text p,
.use-policy-text h5,
.use-policy-text ol {
  text-align: justify;
}

.use-policy-text p {
  font-weight: 300;
}

.use-policy-text ol {
  font-weight: 300;
}

.use-policy-text h2 {
  font-weight: 500;
}

.use-policy-text h5 {
  font-weight: 400;
}

.use-policy-text table {
  margin: 32px 0;
}

.use-policy-text td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

.use-policy-text ol {
  margin-left: 32px;
}
